/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

export const About = () => (
     <Container>
          <Row>
               <Col>
                    <div className="pt-5">
                         
                         <h1>About me</h1>
                         
                    </div>
                    <div className="pt-3">
                    <p>
                    <h3>QA Automation Engineer | CS Grad @ UB | Java | Selenium, Appium, API Testing | Ensuring Software Quality & Reliability | Open to QA Opportunities.</h3>
                         </p>
                         <p>
                         Experienced QA Automation Engineer with a Master’s in Engineering Science (Computer Science) from the University at Buffalo. Specializes in automation testing for web and mobile applications using frameworks like Selenium and Appium. Proficient in API testing with tools such as Postman and RESTful services, and well-versed in JIRA for bug tracking and project management. Skilled in developing and executing detailed test scenarios, test cases, and test conditions to ensure product quality and alignment with business requirements. Actively seeking opportunities in any testing role to leverage my expertise in QA automation and contribute to delivering high-quality, reliable software solutions.</p>
                    </div>
               </Col>
          </Row>
          <Row>
               <Col>
                    <div className="pt-5">
                         <h3>Skills</h3>
                    </div>
                    <div className="pt-3">
                    <ul>
                         <li>Java | Python</li>
                         <li>Selenium WebDriver | Robot Framework | Appium</li>
                         <li>MySQL</li> 
                         <li>JIRA</li>
                         <li>Postman</li>
                         <li>Agile & V-Model</li>
                         <li>REST API</li>
                    </ul>
                    </div>
               </Col>
          </Row>
          <Row>
               <Col>
                    <div className="social-media">
                         <a href = "mailto: singh23@buffalo.edu" target="_blank" rel="noopener noreferrer"  className="pr-3" title="Send Email"><p>Email</p></a>
                         <a href="https://github.com/singhamritanshu" target="_blank" rel="noopener noreferrer" className="pr-3" title="Join on Github"><p>Github</p></a>
                         <a href="https://www.linkedin.com/in/singhamritanshu" target="_blank" rel="noopener noreferrer" className="pr-3" title="Join on Linkedin"><p>Linkedin</p></a>
                         
                    </div>
               </Col>
          </Row>
     </Container>
)   