import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export const Hero = () => (
    <Container className="hero">
        <Row>
            <img src="/images/amr2.png" width="100%" height="300px" alt="amr2" />
        </Row>
    </Container>
)   
