import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { About } from './components/About/about';
import { Project } from './components/Project/project';
import { Blog } from './components/Blog/blog';
import { NoMatch } from './components/Error/nomatch';
import { Header } from './components/default/Header/header';
import { Hero } from './components/Hero/hero'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <link rel="stylesheet" href="path/to/font-awesome/css/font-awesome.min.css"></link>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
        crossorigin="anonymous"
      />
      <Router>
      <Header />
      <Hero />
        <body className="background">
          <Switch>
            <Route exact path="/" component={About} />
            <Route path="/project" component={Project} />
            <Route path="/blog" component={Blog} />
            <Route component={NoMatch} />
          </Switch>
        </body>
      </Router>
    </div>
  );
}

export default App;
