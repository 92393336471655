import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

export const Blog = () => (
     <Container>
          <Row>
               <Col>
                    <div className="pt-5 pb-3">
                         <h6 className="caption-text">LEARN FROM</h6>
                         <h1>Tutorials</h1>
                    </div>
               </Col>
          </Row>
          <Row>
          <Col xs={12} md={4}>
                    <div className="pt-3 d-flex">
                         <Card className="mr-3" style={{ width: '20rem', height: '11rem'  }}>
                              <Card.Body className="float-left">
                                   <Card.Title><b>OpenCV Tutorial</b></Card.Title>
                                   <Card.Text className="project-desc ">
                                   Learn all the fundamentals to get started with image processing using OpenCV.
    </Card.Text>
    <Card.Link href="https://github.com/singhamritanshu/open_cv_python_tutorial" target="_blank">Go to Tutorial</Card.Link>
                              </Card.Body>
                         </Card>
                    </div></Col>
               <Col xs={12} md={4}>
                    <div className="pt-3 d-flex">
                         <Card className="mr-3" style={{ width: '20rem', height: '11rem'  }}>
                              <Card.Body className="float-left">
                                   <Card.Title><b>Numpy, Pandas, Matplotlib</b></Card.Title>
                                   <Card.Text className="project-desc ">
                                   Learn all the fundamentals to get started with Pandas, Numpy & Matplotlib.
                                        
    </Card.Text>
    <Card.Link href="https://github.com/singhamritanshu/numpy_pandas_matplotlib"target="_blank">Go to Tutorial</Card.Link>
                              </Card.Body>
                         </Card>
                    </div></Col>
               
          </Row>
     </Container>
)   
