import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const NoMatch = () => (
     <Container>
          <Row>
               <Col>
                    <div className="mt-5">
                         <p className="error">404</p>
                         <p style={{ textAlign: "center", }}>Something went wrong, please go back to homepage</p>
                    </div>
               </Col>
          </Row>
     </Container>
)