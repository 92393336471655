import React from 'react';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar'

export const Header = () => (
    <Container className="my-3" >
                <Navbar collapseOnSelect expand="lg">
                    <NavLink className="mr-3" to="/">About</NavLink>
                    <NavLink className="mr-3" to="/project">Project</NavLink>
                    <NavLink className="mr-3" to="/blog">Tutorial</NavLink>
                    <a href="Amritanshu Singh.pdf" download>  
                    Resume
                    </a>
                </Navbar>
    </Container>
)
