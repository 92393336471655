import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
export const Project = () => (
     <Container>
          <Row>
               <Col>
                    <div className="pt-5">
                         <h6 className="caption-text">DIVE INTO</h6>
                         <h1>Projects</h1>
                    </div>
               </Col>
          </Row>
          <Row>
               <Col xs={12} md={4}>
                    <div className="pt-3 d-flex">
                         <Card className="mr-3" style={{ width: '20rem', height: '16rem'  }}>
                              <Card.Body className="float-left">
                                   <Card.Title><b>Object Detection</b></Card.Title>
                                   <Card.Text className="project-desc ">
                                   Using a pre trained ssd mobilenet v3 (trained on cocodataset) and opencv to dectect objects in an image or detect multiple objects live from camera.
    </Card.Text>
                                   <Card.Link href="https://github.com/singhamritanshu/object_detection" target="_blank">Go to Project</Card.Link>
                              </Card.Body>
                         </Card>
                    </div></Col>
               <Col xs={12} md={4}>
                    <div className="pt-3 d-flex">
                         <Card className="mr-3" style={{ width: '20rem', height: '16rem'  }}>
                              <Card.Body className="float-left">
                                   <Card.Title><b>Face Recognition using OpenCV</b></Card.Title>
                                   <Card.Text className="project-desc ">
                                        Used opencv inbuilt face recogniser to recognise face and draw bounding box around it.
                                        
    </Card.Text>
                                   <Card.Link href="https://github.com/singhamritanshu/face_recognizer_opencv" target="_blank">Go to Project</Card.Link>
                              </Card.Body>
                         </Card>
                    </div></Col>
               <Col xs={12} md={4}>
                    <div className="pt-3 d-flex">
                         <Card className="mr-3" style={{ width: '20rem', height: '16rem'  }}>
                              <Card.Body className="float-left">
                                   <Card.Title><b>Generating song Lyrics using RNN</b></Card.Title>
                                   <Card.Text className="project-desc ">
                                   A character level RNN using TensorFlow which predicts a new character at every time step.
    </Card.Text>
                                   <Card.Link href="https://github.com/singhamritanshu/Generating_Song_Lyrics" target="_blank">Go to Project</Card.Link>
                              </Card.Body>
                         </Card>
                    </div></Col>
          </Row>
          <Row className="mt-3">
               <Col xs={12} md={4}> 
                    <div className="pt-3 d-flex">
                         <Card className="mr-3" style={{ width: '20rem', height: '11rem'  }}>
                              <Card.Body className="float-left">
                                   <Card.Title><b>Generating Images with GANs</b></Card.Title>
                                   <Card.Text className="project-desc ">
                                   It generates fake handwritten digits like MNIST dataset.
    </Card.Text>
                                   <Card.Link href="https://github.com/singhamritanshu/GeneratinMNISTImages" target="_blank">Go to Project</Card.Link>
                              </Card.Body>
                         </Card>
                    </div></Col>
               
               <Col xs={12} md={4}>
                    <div className="pt-3 d-flex">
                         <Card className="mr-3" style={{ width: '20rem', height: '11rem'  }}>
                              <Card.Body className="float-left">
                                   <Card.Title><b>Book Keeping Web App</b></Card.Title>
                                   <Card.Text className="project-desc ">
                                   A book keeping app using Spring Boot framework and mysql.
    </Card.Text>
                                   <Card.Link href="https://github.com/singhamritanshu/bookKeepingApp"target="_blank">Go to Project</Card.Link>
                              </Card.Body>
                         </Card>
                    </div></Col>

                    <Col xs={12} md={4}>
                    <div className="pt-3 d-flex">
                         <Card className="mr-3" style={{ width: '20rem', height: '11rem'  }}>
                              <Card.Body className="float-left">
                                   <Card.Title><b>Portrait Selfie</b></Card.Title>
                                   <Card.Text className="project-desc ">
                                   Add portrait effect to any photo or click a portrait selfie.
    </Card.Text>
                                   <Card.Link href="https://github.com/singhamritanshu/Portrait_Selfie"target="_blank">Go to Project</Card.Link>
                              </Card.Body>
                         </Card>
                    </div></Col>
          </Row>
     </Container>
)   